import * as React from "react";

interface LayoutProps {
  children?: React.ReactNode;
}

export const Layout: React.FC<LayoutProps> = ({ children }) => {
  return (
    <>
      <main className="max-w-4xl mx-auto">{children}</main>
    </>
  );
};
